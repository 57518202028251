<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          You are not authorized! 🔐
        </h2>
        
        <p class="mb-2">
          You don’t have permission to access this page. Go Home!!
        </p>

        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          :to="loginRoute()"
        >Back to Home</b-button>

        <component :is="imgUrl"></component>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import store from '@/store/index'

import NotAuthorizedSVG from '@/assets/images/pages/not-authorized.svg';
import NotAuthorizedDarkSVG from '@/assets/images/pages/not-authorized-dark.svg';

import { BLink, BImg, BButton } from 'bootstrap-vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    BLink, 
    BImg, 
    BButton, 
    NotAuthorizedSVG,
    NotAuthorizedDarkSVG
  },
  data() {
    return {}
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        return NotAuthorizedDarkSVG
      }
      return NotAuthorizedSVG
    },
  },
  methods: {
    loginRoute() {
      const user = JSON.parse(localStorage.getItem('userData'))
      return getHomeRouteForLoggedInUser(user ? user.role : null)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
